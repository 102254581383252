@import 'npm:normalize.css/normalize.css';


/* Reset */

* {
	box-sizing: border-box 
}

body, h1, h2, p, ul {
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Arial', sans-serif;
	font-size: 18px;
	line-height: 1.6;
}

@media screen and (min-width: 576px) {
    body {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) {
    body {
        font-size: 22px;
    }
}

@media screen and (min-width: 992px) {
    body {
        font-size: 24px;
    }
}

p {
	margin-bottom: 1.5em;
}

p:last-child {
	margin-bottom: 0;
}


/* Typography */

h1, h2, h3 {
	margin-bottom: 0.5em;
}

h1 {
	font-size: 2.5em;
	line-height: 1.2em
}

@media screen and (min-width: 576px) {
    .h1 {
        font-size: 3em;
    }
}

h2 {
	font-size: 1.5em;
	line-height: 1.4em
}


/* Structure */

.container {
    width: 100%;
    margin: 0 auto;
	padding: 0 20px;
}

@media screen and (min-width: 576px) {
    .container {
        width: 540px;
		padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .container {
        width: 720px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        width: 960px;
    }
}

.section {
	padding: 60px 0;
	text-align: center;
}

@media screen and (min-width: 576px) {
    .section {
		padding: 80px 0;
    }
}

@media screen and (min-width: 768px) {
    .section {
		padding: 100px 0;
    }
}

@media screen and (min-width: 992px) {
    .section {
		padding: 120px 0;
    }
}


/* Buttons */

.btn {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border: 2px solid #0069FF;
    border-radius: 4px;
    color: #0069FF;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.btn:hover {
    background-color: #0069FF;
    color: #fff;
    border-color: #0056b3;
}

.btn:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    border-color: #999;
    color: #666;
	transition: none;
}


/* Alerts */

.alert {
	padding: 10px 20px;
    margin-bottom: 2em;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
}

.alert-success {
	background-color: #d5ffd5;
	border-color: #7abd7a;
}

.alert-danger {
	background-color: #ffcbcb;
    border-color: #e1a9a9;
}


/* Spinner */

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #0069FF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
	display: inline-block;
	position: relative;
    top: 5px;
	left: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Section 1: Hero */

#hero {
	position: relative;
	height: 100vh;
	padding: 0;
	background: url(../images/hero-bg.jpg) #f8f8f8 center/cover no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#hero::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#hero > .container {
	z-index: 2;
}


/* Section 2: About Me */

#about {
	background-color: #f5f5f5;
    padding-bottom: 0;
}

.profile-image {
    display: block;
    width: 500px;
    height: auto; /* aspect ratio is set based on width/height set in html */
    max-width: 100%;
    margin: 0 auto;
}


/* Section 3: Contact Form */

form {
    max-width: 400px;
    margin: 0 auto;
	padding: 0 20px;
	text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
	width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 2px solid #0069FF;
    border-radius: 4px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    outline: none;
    border-color: #0069FF;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.7);
	
}

input[type="text"]:disabled,
input[type="email"]:disabled,
textarea:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    border-color: #999;
    color: #666;
}

textarea {
	max-width: 100%;
}


/* Section 4: Footer */

#footer {
	background: #333;
	color: #fff;
	padding: 20px 0;
    font-size: 0.8em;
}